<template>
    <div class="page">
        <div>
            <div class="page-items">
                <el-input class="input" style="width:600px" />
                <el-button type="primary">搜索</el-button>
            </div>
            <div class="page-item">
                <span class="item">我要买车</span>
                <div class="demo-fit">
                    <div class="img-model" v-for="(item,index) in goodsImg" :key="index">
                        <div class="img-tupian">
                            <img :src="item.src" alt="">
                        </div>
                        <div class="img-text">
                            {{item.goodsName}}
                        </div>
                    </div>
                </div>
                <div class="img-model-img" style="display: flex;">
                    <div style="width:70%">
                        <span class="img-model" @click="Allbrands">全部品牌<i class="el-icon-arrow-right" style="margin-top: 3px;"></i>
                            <!-- <div>
                                <el-collapse v-model="activeNames" accordion>
                                    <el-collapse-item title="全部品牌" name="1">
                                    </el-collapse-item>
                                </el-collapse>
                            </div> -->
                        </span>
                        <div class="demo-fit">
                            <span class="img-model" v-for="(model,index) in model" :key="index">{{model}}</span>
                        </div>
                    </div>
                    <div style="width:30%"><img src="../../assets/PCslices/qichetupian2.png"></div>
                </div>
            </div>
            <div class="page-item1">
                <span class="item">全部车源</span>
                <div class="box" @click="details">
                    <ul>
                        <li v-for="(v,index) in json.list" :key="index">
                            <div class="Single">
                                <img v-bind:src="v.src" alt="">
                                <h1 class="car">{{v.car}}</h1>
                                <h4 class="time">{{v.des}}</h4>
                                <p class="num">{{v.price}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bt_ft">
            <span style="margin-top: 20px;">更多车辆</span>
            <span><img src="../../assets/PCslices/tubiao.png"></span>
        </div>
        <div class="footer_foot">
            <div class="footer_foot_left">
                <p class="item">成为二手车交易行业</p>
                <p class="item_bt"> 社会公允规则的引领者</p>
            </div>
            <div>
                <el-button class="footer_foot_right">我要买车</el-button>
            </div>
        </div>
    </div>
</template>

<script scoped>
export default {
    data() {
        return {
            activeNames: "1",

            model: ["3万元以内", "3-5万元", "5-7万元", "7万元以上", "更多"],
            goodsImg: [
                {
                    src: require("../../assets/PCslices/baoma.png"),
                    goodsName: "宝马",
                },
                {
                    src: require("../../assets/PCslices/aodi.png"),
                    goodsName: "奥迪",
                },
                {
                    src: require("../../assets/PCslices/benchi.png"),
                    goodsName: "奔驰",
                },
                {
                    src: require("../../assets/PCslices/bentian.png"),
                    goodsName: "本田",
                },
                {
                    src: require("../../assets/PCslices/fengtian.png"),
                    goodsName: "丰田",
                },
                {
                    src: require("../../assets/PCslices/dazhong.png"),
                    goodsName: "大众",
                },
                {
                    src: require("../../assets/PCslices/bieke.png"),
                    goodsName: "别克",
                },
                {
                    src: require("../../assets/PCslices/fute.png"),
                    goodsName: "福特",
                },
            ],
            json: {
                list: [
                    {
                        src: require("../../assets/tupian/WechatIMG927.png"),
                        car: "起亚K3 2013款 1.6L 自动DLX",
                        des: "2013年 | 8.50万公里",
                        price: "5.29万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG942.png"),
                        car: "马自达3星聘 2011款 1.6L 手动舒适型",
                        des: "2011年 | 10.00万公里",
                        price: "3.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG953.png"),
                        car: "Jeep 指南者 2015款 2.0L两驱豪华版",
                        des: "2015年 | 9.20万公里",
                        price: "9.28万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG966.png"),
                        car: "沃尔沃S60 2011款 2.0T 舒适版",
                        des: "2011年 | 13.12万公里",
                        price: "6.12万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG981.png"),
                        car: "奔驰GLA级 2016款 GLA200 动感型",
                        des: "2016年 | 14.28万公里",
                        price: "15.17万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1000.png"),
                        car: "宝马3系 2017款 318i 时尚型",
                        des: "2017年 | 9.12万公里",
                        price: "16.29万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1005.png"),
                        car: "大众 速腾 2007款 1.80T 自动舒适型",
                        des: "2007年 | 16.89万公里",
                        price: "1.60万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1015.png"),
                        car: "大众 速腾 2009款 1.6L 手动舒适型",
                        des: "2009年 | 9.16万公里",
                        price: "1.80万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1026.png"),
                        car: "奔驰E级 2015款 改款 E 260 L运动型",
                        des: "2015年 | 5.20万公里",
                        price: "20.67万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1042.png"),
                        car: "Jeep 自由侠 2018款 互联大屏版 1.80T 自动高能版",
                        des: "2018年 | 2.56万公里",
                        price: "9.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1055.png"),
                        car: "铃木 雨燕 2011款 1.3L 自动时尚型",
                        des: "2011年 | 4.90万公里",
                        price: "3.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1067.png"),
                        car: "本田 思域 2009款 1.8L 自动经典版",
                        des: "2009年 | 12.89万公里",
                        price: "5.12万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1079.png"),
                        car: "别克 凯越 2015款 1.5L自动经典型",
                        des: "2015年 | 3.29万公里",
                        price: "4.90万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1090.png"),
                        car: "宝马3系 2017款 320Li M 运动型",
                        des: "2017年 | 8.16万公里",
                        price: "20.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1102.png"),
                        car: "丰田 凯美瑞 2012款 2.5G 豪华导航版",
                        des: "2012年 | 16.89万公里",
                        price: "11.78万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1114.png"),
                        car: "雷克萨斯 IS 2009款 300",
                        des: "2009年 | 13.24万公里",
                        price: "9.56万",
                    },
                ],
            },
        };
    },
    methods: {
        //全部品牌
        Allbrands() {},
        //点击详情挑转
        details() {
            this.$router.push("/cardetails");
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    width: 1440px;
    height: 100%;
    background-image: url("../../assets/shouye.png");
    background-repeat: no-repeat;
    display: block;
}
.img-model {
    font-size: 16px;
    display: flex;
    margin: 15px 50px 15px 10px;
}
.page-item {
    background-color: rgb(255, 255, 255);
    height: 240px;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 1px 4px 5px 2px rgb(232, 232, 232);
}
.page-items {
    // background-image: linear-gradient(#91d1e5, #a9d1e417);
    width: 100%;
    height: 100px;
    line-height: 100px;
    margin: 0 auto;
    text-align: center;
}
.page-item1 {
    width: 90%;
    margin: 0 auto;
    .item {
        height: 70px;
        line-height: 70px;
        font-size: 24px;
        padding-left: 60px;
    }
}
.time {
    font-size: 13px;
    color: #999;
}
.img-tupian {
    margin: 0px 10px;
}
.num {
    font-size: 18px;
    color: rgb(255, 0, 0);
}
.item {
    height: 70px;
    line-height: 70px;
    font-size: 24px;
    margin: 15px;
}
.demo-fit {
    display: flex;
}
.Single {
    display: inline-block;
    width: 250px;
    height: 285px;
    margin: 15px;
    cursor: pointer;
}
.box ul {
    display: flex;
    flex-wrap: wrap;
}
.box li {
    box-shadow: 1px 4px 5px 2px rgb(232, 232, 232);
    list-style: none;
    margin: 10px;
    // border: 1px solid #eee;
}
.box img {
    width: 100%;
    height: 168px;
}
.car {
    word-wrap: break-word;
    word-break: break-all;
    white-space: nowrap; /*强制span不换行*/
    display: inline-block; /*将span当做块级元素对待*/
    width: 100%; /*限制宽度 可设px*/
    overflow: hidden; /*超出宽度部分隐藏*/
    text-overflow: ellipsis; /*超出部分以点号代替*/
}
.bt_ft {
    margin: 0 auto;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.footer_foot {
    width: 100%;
    height: 140px;
    background-color: rgb(250, 250, 250);
    display: flex;
    text-align: center;
    margin: auto;
    .footer_foot_left {
        width: 55%;
        .item {
            // height: 70px;
            // line-height: 70px;
            font-size: 24px;
        }
        .item_bt {
            font-size: 24px;
            margin: 0px 40px 0px 150px;
        }
    }
    .footer_foot_right {
        // width: 60%;
        height: 60px;
        background-color: rgb(63, 123, 243);
        font-size: 20px;
        color: #ffffff;
        border-style: none;
        margin: 40px 40px 0px 100px;
    }
}
</style> 

